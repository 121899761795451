























































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';
import { commitAddNotification } from '@/store/main/mutations';

@Component
export default class AdminAISettings extends Vue {
  public gpt_model = '';
  public gpt_use_prem = false;
  public gpt_prem_project_id = '';
  public dialog = false;
  public dialogDelete = false;
  public pageLength = 10;
  public pageStart = 1;
  public personasCount = 0;
  public search = '';
  public headers = [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Description',
      align: 'start',
      value: 'description',
    },
    { text: 'Temperature', value: 'temperature' },
    { text: 'X', value: 'x' },
    { text: 'Y', value: 'y' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
  public personas: {
    description: string;
    temperature: number;
    x: number;
    y: number;
  }[] = [];
  public editedIndex = -1;
  public editedItem = {
    id: 0,
    description: '',
    temperature: 1,
    x: 0,
    y: 0,
  };
  public defaultItem = {
    id: 0,
    description: '',
    temperature: 1,
    x: 0,
    y: 0,
  };

  // File upload
  public selectedFile: File | null = null;
  public isSelecting = false;

  get formTitle() {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
  }

  get importButtonText() {
    return this.selectedFile ? this.selectedFile.name : 'Import File';
  }

  @Watch('dialog') onDialogChanged(val) {
    val || this.close();
  }
  @Watch('dialogDelete') onDialogDeleteChanged(val) {
    val || this.closeDelete();
  }


  public created() {
    this.updateDataTable();
    this.getAISettings();
  }

  public async updateDataTable() {
    const response = await api.getPersonas(readToken(this.$store), this.pageLength, this.pageStart, this.search);
    if (response.status === 200) {
      this.personas = response.data.data;
      this.personasCount = response.data.total;
    }
  }

  public editItem(item) {
    this.editedIndex = this.personas.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  public deleteItem(item) {
    this.editedIndex = this.personas.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public async deleteItemConfirm() {
    const response = await api.deletePersona(readToken(this.$store), this.editedItem.id);
    if (response.status === 200) {
      this.personas.splice(this.editedIndex, 1);
      this.closeDelete();
    }
  }

  public close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public async save() {
    if (this.editedIndex > -1) {
      const response = await api.updatePersona(readToken(this.$store), this.editedItem.id, this.editedItem);
      if (response.status === 200) {
        Object.assign(this.personas[this.editedIndex], this.editedItem);
      }
    } else {
      const response = await api.createPersona(readToken(this.$store), this.editedItem.id, this.editedItem);
      if (response.status === 200) {
        Object.assign(this.editedItem, response.data);
        this.personas.unshift(this.editedItem);
      }
    }
    this.close();
  }

  public async paginationChanged(pagination: { page: number; itemsPerPage: number; pageStart: number; pageStop: number; pageCount: number; itemsLength: number; search: string }) {
    this.pageLength = pagination.itemsPerPage;
    this.pageStart = pagination.page;
    this.updateDataTable();
  }

  public onButtonClick() {
    this.isSelecting = true;
    window.addEventListener('focus', () => {
      this.isSelecting = false;
    }, { once: true });

    const uploader = this.$refs.uploader as HTMLInputElement;
    uploader.click();
  }
  public async onFileChanged(e) {
    this.selectedFile = e.target.files[0];
    if (!this.selectedFile) {
      return;
    }
    const response = await api.importPersonas(readToken(this.$store), this.selectedFile);
    if (response.status === 200) {
      this.updateDataTable();
    }
  }

  public async saveAISettings() {
    try {
      const response = await api.saveAISettings(readToken(this.$store), {
        model: this.gpt_model,
        use_prem: this.gpt_use_prem,
        prem_project_id: this.gpt_prem_project_id,
      });
      if (response.status === 200) {
        commitAddNotification(this.$store, {
          content: 'AI Settings saved',
          color: 'success',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async getAISettings() {
    const response = await api.getAISettings(readToken(this.$store));
    if (response.status === 200) {
      this.gpt_model = response.data.model;
      this.gpt_use_prem = response.data.use_prem;
      this.gpt_prem_project_id = response.data.prem_project_id;
    }
  }
}
